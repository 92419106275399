import AcrossTabs from 'ace-across-tabs';
import { isIE, ieTransferController } from '../utils';

export default class TabParent {
  config: TabParentConfig;
  tab: AcrossTabs.Parent;
  newTabOption: NewTabOption;

  constructor(config: TabParentConfig) {
    this.config = { ...config };

    this.newTabOption = {
      url: '',
      ...config.newTabOption
    };

    this.tab = new AcrossTabs.Parent({
      removeClosedTabs: true,
      onHandshakeCallback: this.onChildConnect.bind(this),
      onChildDisconnect: this.onChildDisconnect.bind(this),
      onChildCommunication: this.onCommunication.bind(this),
    });
  }

  open(): void {
    const { config } = this;
    if(isIE && location.pathname !== '/transfer'){
      ieTransferController(config);
    }
    else {
      this.tab.openNewTab(this.newTabOption);
    }
  }

  closeChild(id: string): void {
    this.tab.closeTab(id);
  }

  closeChildren(): void {
    this.tab.closeAllTabs();
  }

  sendMessage(message: Message): void {
    if (!message.type) message.type = 'custom';
    if (this.tab.getOpenedTabs().length === 0) {
      return;
    }
    const [{ id: tabId }] = this.tab.getOpenedTabs();
    this.tab.broadCastTo(tabId, message);
  }

  onChildDisconnect (detail: object): void {
    if (this.config.onDisconnect) this.config.onDisconnect(detail);
  };

  onCommunication (message: Message): void {
    const { type, ...data } = message;
    if (this.config.onMessage) this.config.onMessage(data);
  }

  onChildConnect (): void {
    if (this.config.onChildConnect) this.config.onChildConnect();
  }
}
