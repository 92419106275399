const iframeStyle = `
    width: 100%; 
    height: 100%; 
    position: fixed; 
    left: 0; 
    right: 0; 
    top: 0; 
    bottom: 0; 
    border: 0; 
    z-index: 9999; 
    box-sizing: border-box;
    display: none;
`;

export default class FrameParent {
  child: Nullable<Window>;
  iframe: Nullable<HTMLIFrameElement>;
  iframeAttrs: IframeAttributes;
  config: FrameParentConfig;

  constructor(config: FrameParentConfig) {
    this.config = {
      isOpen: true,
      ...config
    };
    this.iframeAttrs = {
      src: '',
      id: 'ace_editor',
      style: iframeStyle,
      ...config.iframeAttrs,
    };
    this.iframe = null;
    this.child = null;
    window.addEventListener('DOMContentLoaded', () => {
      this.init();
      if (this.config.isOpen) this.open();
    });
  }

  init(): void {
    this.iframe = document.createElement('iframe');

    Object.keys(this.iframeAttrs).forEach((key) => {
      this.iframe?.setAttribute(key, this.iframeAttrs[key]);
    });
    document.body.appendChild(this.iframe);

    this.child = this.iframe?.contentWindow;
    window.addEventListener('message', this.onCommunication.bind(this));
  }

  open(): void {
    FrameParent.handleAfterOpen();
    if (this.iframe) {
      this.iframe.setAttribute('src', this.iframeAttrs['src']);
      this.iframe.style.setProperty('display', 'block', 'important');
    }
    if (this.config.onOpen) this.config.onOpen();
  }

  close(): void {
    if (this.iframe) {
      this.iframe.style.setProperty('display', 'none', 'important');
      this.iframe.setAttribute('src', '');
    }
    FrameParent.handleAfterClose();
    window.removeEventListener('message', this.onCommunication.bind(this));
    if (this.config.onClose) this.config.onClose();
  }

  sendMessage(message: Message): void {
    if (!message.type) message.type = 'custom';
    this.child?.postMessage(message, '*');
  }

  onCommunication (e: MessageEvent): void {
    const { type, ...data } = e.data;
    if (!type) return;
    if (this.config.onMessage) this.config.onMessage(data);
  }

  static handleAfterOpen() {
    document.querySelector('html')?.style.setProperty('overflow', 'hidden');
    document.querySelector('html')?.style.setProperty('height', 'auto');
    document.body.style.setProperty('overflow', 'hidden');
    document.body.style.setProperty('height', 'auto');
  }

  static handleAfterClose() {
    document.querySelector('html')?.style.setProperty('overflow', '');
    document.querySelector('html')?.style.setProperty('height', '');
    document.body.style.setProperty('overflow', '');
    document.body.style.setProperty('height', '');
  }
}
