import TabChild from '../Ancestor/TabChild';
import { checkIeVersionCallback } from '../utils';
import { EditorMessages } from '../utils/constants';

export default class TabChildEditor extends TabChild {
  options: TabChildConfig = {};

  constructor(config: TabChildConfig) {
    super(config);
  }

  onInit(): void {
    checkIeVersionCallback((version: number) => this.sendMessage({ type: EditorMessages.FailIeVersion, ...{ version } }));
    this.sendMessage({ type: EditorMessages.InitEditor });
  };

  saveContents(editorContents: EditorContents): void {
    this.sendMessage({ type: EditorMessages.SaveContents, ...editorContents });
    this.close();
  }

  get isParentConnecting(): boolean {
    return !!this.tab.getTabInfo().id;
  }

  onCommunication (message: Message | string): void {
    super.onCommunication(message);

    if (message instanceof Object) {
      const { type, ...data } = message;
      switch (type) {
        case (EditorMessages.InitEditor):
          this.options = data;
          if (this.config.onInit) this.config.onInit(data);
          break;
        default:
          if (this.config.onMessage) this.config.onMessage(data);
      }
    }
  };
}
