import AcrossTabs from 'ace-across-tabs';

export default class TabChild {
  tab: AcrossTabs.Child;
  config: TabChildConfig;

  constructor(config: TabChildConfig) {
    this.config = { ...config };

    this.tab = new AcrossTabs.Child({
      onInitialize: this.onInit.bind(this),
      onParentDisconnect: this.onParentDisconnect.bind(this),
      onParentCommunication: this.onCommunication.bind(this)
    });

    // 상위 부모 탭의 이름이 없는 경우 옵션 전달 없이 초기화
    if (this.tab.tabParentName === null) {
      if (this.config.onInit) this.config.onInit(null);
    }
  }

  close(): void {
    window.close();
  }

  onInit (): void {
    if (this.config.onInit) this.config.onInit(null);
  };

  onParentDisconnect (detail: object): void {
    if (this.config.onDisconnect) this.config.onDisconnect(detail);
  };

  sendMessage(message: Message): void {
    if (!message.type) message.type = 'custom';
    this.tab.sendMessageToParent(message);
  };

  onCommunication (message: Message | string): void {
    if (typeof message === 'string') {
      if (this.config.onMessage) this.config.onMessage(message);
      return;
    }
  };
}
