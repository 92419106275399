import FrameChild from '../Ancestor/FrameChild';
import { checkIeVersionCallback } from '../utils';
import { EditorMessages } from '../utils/constants';

export default class FrameChildEditor extends FrameChild {
  options: EditorOptions = {};

  onParentCommunication(e: MessageEvent): void {
    if (!e.data) return;
    const { type, ...data } = e.data;
    switch (type) {
      case (EditorMessages.InitEditor):
        this.options = data;
        if (this.config.onInit) this.config.onInit(data);
        checkIeVersionCallback((version: number) => this.sendMessage({ type: EditorMessages.FailIeVersion, ...{ version } }));
        break;
    }
  }

  saveContents(editorContents: EditorContents): void {
    this.sendMessage({ type: EditorMessages.SaveContents, ...editorContents });
  }
}
