import FrameChildEditor from './FrameChildEditor';
import TabChildEditor from './TabChildEditor';

export default class EditorChild {
  constructor(config: EditorChildConfig) {
    return EditorChild.isFrameOpen ? new FrameChildEditor(config) : new TabChildEditor(config);
  }
  static get isFrameOpen(): boolean {
    return window.self !== window.top;
  }
}
