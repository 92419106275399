import { isFrameParentConnecting } from '../utils';

export default class FrameChild {
  config: FrameChildConfig;
  parent: Nullable<Window>;

  constructor(config: FrameChildConfig) {
    this.config = { ...config };
    this.parent = null;
    this.init();
  }

  init(): void {
    if (this.isParentConnecting) {
      this.parent = window.parent;
      window.addEventListener('message', this.onParentCommunication.bind(this));
    } else {
      // 상위 부모 페이지가 없는 경우 옵션 전달 없이 초기화
      if (this.config.onInit) this.config.onInit(null);
    }
  }

  sendMessage(message: Message): void {
    if (!this.isParentConnecting) return;
    if (!message.type) message.type = 'custom';
    this.parent?.postMessage(message, '*');
  };

  onParentCommunication(e: MessageEvent): void {
    if (!this.isParentConnecting || !e.data) return;
    if (this.config.onMessage) this.config.onMessage(e.data);
  }

  get isParentConnecting(): boolean {
    return isFrameParentConnecting();
  }
}
