import { IE_TRANSFER_ELEMENTS, TRANSFER_URL } from '../../utils/constants';

export const isIE = navigator.userAgent.match(/MSIE|Trident|Edge/);

/**
 * get IE version number
 * @returns {number}
 */
export const getIeVersion = () => {
  if (isIE) {
    const IEVersion = ((navigator.userAgent.match(/(?:MSIE |Trident.*rv:|Edge\/)(\d+(\.\d+)?)/)) || [])[1];
    const { documentMode: msie } = window.document;
    return parseInt(msie || IEVersion, 10);
  }
  return Infinity;
};

export const isFrameParentConnecting = (): boolean => window.location !== window.parent.location;

/**
 * ie용 iframe form 전송
 * @param config
 */
export const ieTransferController = (config: ParentConfig) => {
  const { FORM_NAME, IFRAME_NAME } = IE_TRANSFER_ELEMENTS;
  const aceIframeElem = document.querySelector(`iframe[name=${IFRAME_NAME}]`);
  if (aceIframeElem !== null) {
    aceIframeElem?.parentNode?.removeChild(aceIframeElem);
  }
  const aceFormElem = document.getElementById(FORM_NAME);
  if (aceFormElem !== null) {
    aceFormElem?.parentNode?.removeChild(aceFormElem);
  }
  const aceIframe = document.createElement('iframe');
  aceIframe.name = IFRAME_NAME;
  aceIframe.setAttribute('height', '0px');
  aceIframe.setAttribute('width', '0px');
  document.body.appendChild(aceIframe);

  const aceForm = document.createElement('form');
  aceForm.setAttribute('method', 'post');
  aceForm.setAttribute('id', FORM_NAME);
  aceForm.setAttribute('target', IFRAME_NAME);
  aceForm.setAttribute('action', TRANSFER_URL);

  const channelField = document.createElement('input');
  channelField.setAttribute('type', 'hidden');
  channelField.setAttribute('name', 'channel');
  channelField.setAttribute('value', config.options?.channel || '');
  aceForm.appendChild(channelField);

  const channelTypeField = document.createElement('input');
  channelTypeField.setAttribute('type', 'hidden');
  channelTypeField.setAttribute('name', 'channelType');
  channelTypeField.setAttribute('value', config.options?.channelType || '');
  aceForm.appendChild(channelTypeField);

  const editorJsonField = document.createElement('input');
  editorJsonField.setAttribute('type', 'hidden');
  editorJsonField.setAttribute('name', 'editorTemplateContent');
  editorJsonField.setAttribute(
    'value',
    encodeURIComponent(config.options?.editorContents?.editorTemplateContent || '')
  );
  aceForm.appendChild(editorJsonField);

  const editorContentsField = document.createElement('input');
  editorContentsField.setAttribute('type', 'hidden');
  editorContentsField.setAttribute('name', 'editorTemplateJson');
  editorContentsField.setAttribute(
    'value',
    encodeURIComponent(config.options?.editorContents?.editorTemplateJson || '')
  );

  aceForm.appendChild(editorContentsField);
  document.body.appendChild(aceForm);

  const iframeForm = <HTMLFormElement>document.getElementById(FORM_NAME);
  iframeForm !== null && iframeForm.submit();

  window.addEventListener(
    'message',
    ({ origin, data }) => {
      if (origin.indexOf('editor.11st.co.kr') === -1 || !data) {
        return;
      }
      if (typeof config.onSave === 'function') {
        config.onSave(data);
      }
    },
    false
  );
};

export const checkIeVersionCallback = (callback: Function): void => {
  const ieVersionNum = getIeVersion();
  if (ieVersionNum === 10) {
    alert('현재 사용중인 Internet Explorer(인터넷 익스플로러) 브라우저 버전이 낮아 일부 기능은 정상적으로 작동하지 않을 수도 있습니다. Internet Explorer를 최신 버전으로 업데이트 또는 Chrome(크롬) 브라우저를 사용해주세요. 11Editor는 Chrome 브라우저에 최적화되어 있습니다');
  } else if (ieVersionNum < 10) {
    alert('현재 사용 중인 Internet Explorer(인터넷 익스플로러) 브라우저 버전이 낮아 기능이 정상적으로 작동하지 않습니다. Internet Explorer를 최신 버전으로 업데이트 또는 Chrome(크롬) 브라우저를 사용해주세요. 11Editor는 Chrome 브라우저에 최적화되어 있습니다');
    if (typeof callback === 'function') callback(ieVersionNum);
  }
}
