export enum EditorMessages {
    InitEditor = 'InitEditor',
    SaveContents = 'SaveContents',
    FailIeVersion = 'FailIeVersion',
}

export enum ProductMessages {
    InitProduct = 'InitProduct',
    GetProductData = 'GetProductData',
}

export enum CloudMessages {
    InitCloud = 'InitCloud',
    GetData = 'GetData',
}
