import FrameParent from '../Ancestor/FrameParent';
import { EditorMessages } from '../utils/constants';
import { EDITOR_URL } from '../utils/config';

export default class EditorParent extends FrameParent {
  constructor(config: FrameParentConfig) {
    super({
      ...config,
      iframeAttrs: {
        src: EDITOR_URL
      }
    });
  }

  open() {
    super.open();
    if (this.iframe) {
      this.iframe.onload = () => this.sendMessage({ type: EditorMessages.InitEditor, ...this.config.options });
    }
  }

  onCommunication (e: MessageEvent): void {
    const { type, ...data } = e.data;
    switch (type) {
      case (EditorMessages.SaveContents):
        if (this.config.onSave) this.config.onSave(data);
        this.close();
        break;
      case (EditorMessages.FailIeVersion):
        if (this.config.onFailIe) {
          this.config.onFailIe(data);
          this.close();
        }
        break;
    }
  }
}
