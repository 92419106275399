import TabParent from '../Ancestor/TabParent';
import { EditorMessages } from '../utils/constants';
import { EDITOR_URL } from '../utils/config';
import { isIE } from '../utils';

export default class EditorParent extends TabParent {
  newTabOption: NewTabOption;

  constructor(config: TabParentConfig) {
    super(config);

    this.newTabOption = {
      url: EDITOR_URL,
      windowName: `ACE_${config.options?.channel || ''}`,
      windowFeatures: isIE ? `height=${screen.height},width=${screen.width},scrollbars=yes,resizable=yes,left=0,top=0` : ''
    };
  }

  onCommunication (message: Message): void {
    const { type, ...data } = message;
    switch (type) {
      case (EditorMessages.InitEditor):
        this.sendMessage({
          type: EditorMessages.InitEditor,
          ...this.config.options,
        });
        break;
      case (EditorMessages.SaveContents):
        if (this.config.onSave) this.config.onSave(data);
        break;
      case (EditorMessages.FailIeVersion):
        if (this.config.onFailIe) {
          this.config.onFailIe(data);
          this.closeChildren();
        }
        break;
      default:
        if (this.config.onMessage) this.config.onMessage(data);
    }
  }
}
